import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputAdornment, Link, makeStyles } from '@material-ui/core'
import { AlternateEmail, Lock, VpnKey } from '@material-ui/icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import { registerLog } from 'local_redux/actions/logActions'
import { checkRedirect } from './helpers/check-redirect'
import { confirmNotAuthUser } from './helpers/confirm-not-auth-user'
import { login, loginWithMFACode, sendEmailCode, loginWithEmailCode } from 'local_redux/actions/userActions'
import { USER_LOGIN_MFA_CODE_RESET, USER_LOGIN_EMAIL_CODE_RESET } from 'local_redux/constants/userConstants'
import styles from './styles/loginScreenStyles'

const useStyles = makeStyles(styles)

const LoginScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const location = useLocation()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [validationError, setValidationError] = useState('')
	const [cardAnimaton, setCardAnimation] = useState('cardHidden')
	const [mfaActivated, setMfaActivated] = useState(false)
	const [errorMFA, setErrorMFA] = useState(false)
	const [error, setError] = useState(false)
	const [mfaCode, setMfaCode] = useState('')
	const [emailCode, setEmailCode] = useState('')
	const [isVisitor, setIsVisitor] = useState(false)
	const [alert, setAlert] = useState(null)

	const { loadingUserInfo, errorUserInfo, userInfo, successUserInfo } = useSelector((state) => state.userLogin)
	const { loadingUserLoginWithMFA, successUserLoginWithMFA, errorUserLoginWithMFA } = useSelector(
		(state) => state.userLoginWithMFA,
	)
	const { successUserEmailCode, errorUserEmailCode } = useSelector((state) => state.userSendEmailCode)
	const { loadingSendUserEmailCode, successSendUserEmailCode, errorSendUserEmailCode } = useSelector(
		(state) => state.userCheckEmailCode,
	)

	const redirectInvestor = location.search ? location.search.split('=')[1] : '/admin/projects'
	const redirectAdmin = location.search ? location.search.split('=')[1] : '/admin/user-page'
	const redirects = { redirectInvestor, redirectAdmin }

	useEffect(() => {
		return () => {
			dispatch({ type: USER_LOGIN_MFA_CODE_RESET })
		}
	}, [])
	useEffect(() => {
		if (userInfo && successUserLoginWithMFA) {
			localStorage.setItem('mfact', true)
			checkRedirect(userInfo, navigate, redirects)
		}
	}, [navigate, userInfo, redirectInvestor, redirectAdmin, successUserLoginWithMFA])
	useEffect(() => {
		if (userInfo && successSendUserEmailCode) {
			dispatch({ type: USER_LOGIN_EMAIL_CODE_RESET })
			checkRedirect(userInfo, navigate, redirects)
		}
	}, [navigate, userInfo, redirectInvestor, redirectAdmin, successSendUserEmailCode])
	useEffect(() => {
		if (userInfo) {
			if (userInfo?.mfaSecretConfig?.base32 && !localStorage.getItem('mfact')) {
				return setMfaActivated(true)
			}
			if (userInfo?.role === 'investor') {
				const info = email ? { email: email.trim() } : null
				if (!info) return
				dispatch(sendEmailCode(info))
				return setIsVisitor(true)
			}
			if (errorUserEmailCode) {
				setAlert(
					<SweetAlert
						info
						style={{ display: 'block', marginTop: '-100px', color: '#333333' }}
						title='¡Acceso denegado!'
						onConfirm={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
						onCancel={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Hubo un error al enviar el código de acceso a su correo.
					</SweetAlert>,
				)
			}
			if (!userInfo?.tempAccess) {
				setAlert(
					<SweetAlert
						info
						style={{ display: 'block', marginTop: '-100px', color: '#333333' }}
						title='¡Acceso denegado!'
						onConfirm={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
						onCancel={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Su usuario no cumple con los requisitos de seguridad para acceder al sistema.
					</SweetAlert>,
				)
			} else {
				checkRedirect(userInfo, navigate, redirects)
			}
		}
	}, [navigate, userInfo, redirectInvestor, redirectAdmin])
	useEffect(() => {
		if (errorUserLoginWithMFA) {
			setErrorMFA(errorUserLoginWithMFA)
		}
	}, [errorUserLoginWithMFA])
	useEffect(() => {
		if (errorSendUserEmailCode) {
			setError(errorSendUserEmailCode)
		}
	}, [errorSendUserEmailCode])
	useEffect(() => {
		let id = setTimeout(function () {
			setCardAnimation('')
		}, 200)

		return function cleanup() {
			window.clearTimeout(id)
		}
	})
	useEffect(() => {
		if (errorUserInfo) {
			handleInvestmentLog(errorUserInfo) // eslint-disable-line no-use-before-define
		} else if (successUserInfo) {
			handleInvestmentLog() // eslint-disable-line no-use-before-define
		}
	}, [errorUserInfo, successUserInfo])
	useEffect(() => {
		if (errorUserEmailCode) {
			setError(errorUserEmailCode)
			setIsVisitor(false)
		}
	}, [errorUserEmailCode])

	const loginHandler = (e) => {
		e.preventDefault()

		if (!email || !password) {
			return setValidationError('Por favor complete los campos para iniciar sesión')
		}
		if (validationError) {
			setValidationError('')
		}

		dispatch(login(email.trim(), password))
	}
	const handleInvestmentLog = (error) => {
		const type = !error ? 'USER_LOGIN_SUCCESS' : 'USER_LOGIN_FAIL'

		if (!error) {
			let logInfo = {
				type: {
					type: 'action',
					actionType: type,
				},
				user: {
					id: userInfo._id,
					email: userInfo.email,
					name: userInfo.name,
					lastName: userInfo?.lastName,
					role: userInfo.role,
				},
			}

			dispatch(registerLog(logInfo))
		} else {
			let logInfo = {
				type: {
					type: 'error',
					error: {
						errorType: type,
						errorMessage: error,
					},
				},
				user: {
					email,
				},
			}
			dispatch(registerLog(logInfo))
		}
	}
	const loginMFAHandler = (e) => {
		e.preventDefault()
		if (mfaCode) {
			const info = {
				token: mfaCode.trim(),
			}
			dispatch(loginWithMFACode(info))
		}
	}
	const loginWithEmail = (e) => {
		e.preventDefault()

		const codeInfo =
			emailCode && email
				? {
						email,
						code: emailCode,
				  }
				: null
		if (!codeInfo) {
			setAlert(
				<SweetAlert
					info
					style={{ display: 'block', marginTop: '-100px', color: '#333333' }}
					title='¡Datos incompletos!'
					onConfirm={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
					onCancel={() => confirmNotAuthUser(setEmail, setPassword, setAlert, dispatch)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Debe ingresar un código.
				</SweetAlert>,
			)
		}
		dispatch(loginWithEmailCode(codeInfo))
	}

	return (
		<div className={classes.container}>
			<GridContainer justifyContent='center'>
				<GridItem xs={12} sm={8} md={4}>
					{!mfaActivated && !isVisitor && (
						<form onSubmit={loginHandler} autoComplete='off'>
							<Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
									<h4 className={classes.cardTitle}>Acceder ShareHolders</h4>
								</CardHeader>
								<CardBody>
									<CustomInput
										labelText='Correo Electrónico...'
										id='email'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: 'email',
											value: email,
											onChange: (e) => setEmail(e.target.value),
											endAdornment: (
												<InputAdornment position='end'>
													<AlternateEmail className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
										}}
									/>
									<CustomInput
										labelText='Contraseña'
										id='password'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: 'password',
											value: password,
											onChange: (e) => setPassword(e.target.value),
											endAdornment: (
												<InputAdornment position='end'>
													<Lock className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											autoComplete: 'off',
										}}
									/>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<GridContainer>
										<GridItem xs={12}>
											<Button type='submit' color='primary' size='lg' block>
												{loadingUserInfo ? 'Iniciando...' : 'Ingresar'}
											</Button>
										</GridItem>
										<GridItem xs={12} className={classes.mainBtnRecovery}>
											<Link onClick={() => navigate('/auth/recover')} className={classes.btnRecovery} variant='body2'>
												¿Olvidó su contraseña?
											</Link>
										</GridItem>
										{errorUserInfo && (
											<GridItem xs={12}>
												<div className={classes.messageError}>{errorUserInfo}</div>
											</GridItem>
										)}
										{validationError && (
											<GridItem xs={12}>
												<div className={classes.messageError}>{validationError}</div>
											</GridItem>
										)}
									</GridContainer>
								</CardFooter>
							</Card>
						</form>
					)}
					{mfaActivated && (
						<form onSubmit={loginMFAHandler}>
							<Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
									<h4 className={classes.cardTitle}>Verificación MFA</h4>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<p>
												Escriba el código de seguridad que aparece en <b>Microsoft Authenticator</b>
											</p>
										</GridItem>
										<GridItem xs={12}>
											<CustomInput
												labelText='6 Dígitos'
												id='6-digits'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													type: '6-digits',
													maxLength: 6,
													minLength: 6,
													type: 'text',
													value: mfaCode,
													onChange: (e) => {
														dispatch({ type: USER_LOGIN_MFA_CODE_RESET })
														setErrorMFA(false)
														setMfaCode(e.target.value)
													},
													endAdornment: (
														<InputAdornment position='end'>
															<VpnKey className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
												}}
											/>
										</GridItem>
										{errorMFA && (
											<GridItem xs={12}>
												<div className={classes.messageError}>Código incorrecto</div>
											</GridItem>
										)}
									</GridContainer>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<GridContainer>
										<GridItem xs={12}>
											<Button type='submit' size='lg' block color='primary' disabled={loadingUserLoginWithMFA}>
												{loadingUserLoginWithMFA ? 'Verificando...' : 'Verificar'}
											</Button>
										</GridItem>
									</GridContainer>
								</CardFooter>
							</Card>
						</form>
					)}
					{!mfaActivated && isVisitor && successUserEmailCode && (
						<form onSubmit={loginWithEmail}>
							<Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
									<h4 className={classes.cardTitle}>Verificación de Identidad</h4>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<p>Escriba el código de acceso que le enviamos a su cuenta de correo electrónico.</p>
										</GridItem>
										<GridItem xs={12}>
											<CustomInput
												labelText='6 Dígitos'
												id='6-digits'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													type: '6-digits',
													maxLength: 6,
													minLength: 6,
													type: 'text',
													value: emailCode,
													onChange: (e) => {
														dispatch({ type: USER_LOGIN_EMAIL_CODE_RESET })
														setEmailCode(false)
														setEmailCode(e.target.value)
													},
													endAdornment: (
														<InputAdornment position='end'>
															<VpnKey className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
												}}
											/>
										</GridItem>
										{error && (
											<GridItem xs={12}>
												<div className={classes.messageError}>{error}</div>
											</GridItem>
										)}
									</GridContainer>
									<CardFooter className={classes.justifyContentCenter}>
										<GridContainer>
											<GridItem xs={12}>
												<Button type='submit' size='lg' block color='primary'>
													{loadingSendUserEmailCode ? 'Verificando código...' : 'Verificar código'}
												</Button>
											</GridItem>
										</GridContainer>
									</CardFooter>
								</CardBody>
							</Card>
						</form>
					)}
				</GridItem>
			</GridContainer>
			{alert}
		</div>
	)
}

export default LoginScreen
